import { useAuth } from "$components/common/AuthWrapper";
import { GENERATE_COUPON } from "$frontend/query/user";
import { useQuery } from "@apollo/client";
import { useCompute } from "./useCompute";
import { useCurrentTime } from "./useCurrentTime";
export const useCouponAvailable = globalThis.window ? (id)=>{
    var _data_generate_coupon, _data_generate_coupon_expired_at;
    let { data, refetch } = useQuery(GENERATE_COUPON, {
        variables: {
            id
        },
        skip: !useAuth()
    });
    return {
        id: useCompute((generated, now)=>generated && (null == generated.expired_at || Date.parse(generated.expired_at) > now + 60000) && null == generated.used_at ? generated.id : void 0, null == data ? void 0 : data.generate_coupon, useCurrentTime()),
        expired_at: null != (_data_generate_coupon_expired_at = null == data ? void 0 : null == (_data_generate_coupon = data.generate_coupon) ? void 0 : _data_generate_coupon.expired_at) ? _data_generate_coupon_expired_at : void 0,
        refetch
    };
} : ()=>({
        refetch: ()=>Promise.resolve()
    });
